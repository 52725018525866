import axios from "axios";
const customAxios = axios.create({
  baseURL: "https://api.pexels.com",
});
const requestHandler = (request) => {
  request.headers.Authorization =
    "iZ4hQwhhOhpHmT5j4XonFuA4XxlE12K6BlTxgFdZa6gKZv6NteqOOETd";
  return request;
};
const responseHandler = (response) => {
  return response;
};
const errorHandler = (error) => {
  return Promise.reject(error);
};
customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);
customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);
export default customAxios;
