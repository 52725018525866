import axios from '@/plugins/gcApiService.js'
const state = {
  tags: [],
  isGcTokenUpdate : false
};

// mutations
const mutations = {
  SET_TAGS(state, value) {
    state.tags = value
  },
  SET_GC_UPDATE(state) {
    state.isGcTokenUpdate = true
  },
}

// getters
const getters = {
  getTagsData(state) {
    return state.tags
  },
  isGCUpdate(state) {
    return state.isGcTokenUpdate
  },
}
//actions
const actions = {
  async createIntegrationAction() {
    let resp = await axios.post(`/api-v2/integration-actions`, {});
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async pullNextAction({ }, payload) {
    let resp = await axios.put(`/api-v2/integration-actions/${payload.actionId}/pull-field`, payload.responses);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async saveDirectIntegration({ }, payload) {
    let resp = await axios.post(`/api-v2/integration-actions/${payload.actionId}/save`, payload.actionResponses);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async getIntegrationAction({ }, actionId) {
    let resp = await axios.get(`/api-v2/integration-actions/${actionId}`);
    return resp;
  },
  async getTagList({ commit }) {
    // let resp = await axios.get("/api-v2/tags");
    // console.log('t resp',resp)
    // if (resp.status == 200) {
    //   commit("SET_TAGS",resp.data);
    // }
    // return resp;

    try {
      const tags = await window.GCGetTagsList();
      if (tags) {
        commit("SET_TAGS", tags);
      }
      return tags;
    } catch (error) {
      console.log(error)
    }
    return null;
  },
  checkGCupdate({commit}) {
    commit("SET_GC_UPDATE")
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
