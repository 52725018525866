import axios from '@/plugins/apiService.js' 
const state = {
    quiz:[],
    quizGruops:[],
    singleQuiz:{},
    questions:[],
    template:[],
    redirectData:[],
    allEntries : [],
    qualifiedEntries : [],
    disqualifiedEntries : [],
    tags:[],
    allQuestionData:[],
    questionInputs:[],
    count:0,
    serchQuestion:{},
    powerTypeQuestion:[],
    allQuestion:[],
    jumpData:[],
    selectedSetting:'main_setting',
    editorType: null,
    currentQuestion : null,
    quizcategory : [],
};


// mutations
const mutations = {
  SET_QUIZ(state, value) {
    state.quiz = value;
  },
  SET_QUIZ_GROUP(state, value) {
    state.quizGruops = value;
  },
  SET_SINGLE_QUIZ(state, value) {
    state.singleQuiz = value;
  },
  SEt_QUESTIONS(state, value) {
    state.questions = value;
  },
  SET_TEMPLATE(state, value) {
    state.template = value;
  },
  GET_REDIRECT_SCREEN_DATA(state, value) {
    state.redirectData = value;
  },
  SET_ALL_ENTRIES_DATA(state, value) {
    state.allEntries = value;
  },
  SET_TAGS(state, value) {
    state.tags = value;
  },
  SET_ALL_QUESTION(state, value) {
    state.allQuestionData = value;
  },
  SET_QUESTION_INPUT(state, value) {
    state.questionInputs = value;
  },
  increment_count(state) {
    state.count++;
  },
  SET_SERCH_QUESTION(state, value) {
    state.serchQuestion = value;
  },
  SET_POWERTYPE_QUESTION(state, value) {
    state.powerTypeQuestion = value;
  },
  SET_ALL_QUESTION_JUMP(state, value) {
    state.allQuestion = value;
  },
  SET_JUMP_DATA(state, value) {
    state.jumpData = value;
  },
  SET_SELECTED_SETTING(state, value) {
    state.selectedSetting = value.selected_setting;
    state.editorType = value.editor_type;
  },
  SET_CURRENT_QUESTION(state, value) {
    state.currentQuestion = value;
  },
  SET_QUIZ_CATEGORY(state, value) {
    state.quizcategory = value;
  }
};

// getters
const getters = {
  getquizData(state){
      return state.quiz
  },
  getquizGroupData(state){
    return state.quizGruops
  },
  getquizSingleData(state){
    return state.singleQuiz
  },
  getQuestions(state){
    return state.questions
  },
  getTemplate(state){
    return state.template
  },
  getRedirectData(state){
    return state.redirectData
  },
  getAllEntriesData(state){
    return state.allEntries
  },
  getTagsData(state){
    return state.tags
  },
  getAllQuestionData(state){
    return state.allQuestionData
  },
  getAllQuestionInputData(state){
    return state.questionInputs 
  },
  getCounter(state){
    return state.count
  },
  getSerchQuestion(state){
    return state.serchQuestion
  },
  getPowerTypeQuestion(state){
    return state.powerTypeQuestion
  },
  getAllQuestion(state){
    return state.allQuestion
  },
  getallJumpData(state){
     return state.jumpData
  },
  getSelectedSetting(state){
     return state.selectedSetting
  },
  getEditorType(state){
    return state.editorType
  },
  getCurrentQuestion(state){
    return state.currentQuestion;
  },
  getQuizCategory(state){
    return state.quizcategory;
  }
}
//actions
const actions = {
  /** User login  **/
  async getQuizGroup({ commit }, data) {
    let resp = await axios.get("/api/quiz-group?query=" + data);
    if (resp.data.status == true) {
      commit("SET_QUIZ_GROUP", resp.data.data);
    }
    return resp;
  },
  async getQuiz({ commit }, page) {
    let resp = await axios.get(
      "/api/quiz/get-list?page=" +
        page.page +
        "&group_id=" +
        page.sort_group_id +
        "&query=" +
        page.query
    );
    if (resp.data.status == true) {
      commit("SET_QUIZ", resp.data.data);
    }
    return resp;
  },
  async addQuizGroup({ dispatch }, data) {
    let resp = await axios.post("/api/quiz-group/add", data);
    if (resp.data.status == true) {
      dispatch("getQuizGroup", "");
    }
    return resp;
  },
  async deleteGroup({ dispatch }, data) {
    let resp = await axios.get("/api/quiz-group/delete/" + data);
    if (resp.data.status == true) {
      dispatch("getQuizGroup", "");
    }
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async addQuiz({ dispatch }, data) {
    let resp = await axios.post("/api/quiz/add", data);
    if (resp.data.status == true) {
      let maindata = {
        page: 1,
        sort_group_id: "",
        query: "",
      };
      dispatch("getQuiz", maindata);
    }
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async cloneQuiz({ dispatch }, data) {
    let resp = await axios.get("/api/quiz/clone/" + data);
    if (resp.data.status == true) {
      let maindata = {
        page: 1,
        sort_group_id: "",
        query: "",
      };
      dispatch("getQuiz", maindata);
    }
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async deleteQuiz({}, data) {
    let resp = await axios.get("/api/quiz/delete/" + data);
    // if(resp.data.status==true){
    //   dispatch('getQuiz',data.page)
    // }
    return resp;
  },
  async getSingleQuiz({ commit }, id) {
    commit("SET_SINGLE_QUIZ", {});
    let resp = await axios.get("/api/quiz/detail/" + id);
    if (resp.data.status == true) {
      commit("SET_SINGLE_QUIZ", resp.data.data);
    }
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async saveQuestion({}, data) {
    let resp = await axios.post("/api/question/add", data);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async deleteQuestion({}, id) {
    let resp = await axios.get("/api/question/delete/" + id);
    return resp;
  },
  async setQuestions({ commit }, data) {
    let resp = await axios.get(
      "/api/question/index/" + data.quiz_id + "/" + data.type
    );
    if (resp.data.status == true) {
      commit("SEt_QUESTIONS", resp.data.data);
    }
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateRotation({}, data) {
    let resp = await axios.post("/api/question/update-rotation", data);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateNameandDes({}, data) {
    let resp = await axios.post(
      "/api/question/update-question-title-des",
      data
    );
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async addInput({}, data) {
    let resp = await axios.post("/api/question/add-input", data);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async addInputValue({}, data) {
    let resp = await axios.post("/api/question/add-input-value", data);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async deleteQuestionInput({}, id) {
    let resp = await axios.get("/api/question/delete-input/" + id);
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async updateRequire({}, data) {
    let resp = await axios.post("/api/question/update-required-question", data);
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async updateMultiple({}, data) {
    let resp = await axios.post("/api/question/update-multiple-question", data);
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async updateHidden({}, data) {
    let resp = await axios.post("/api/question/update-hidden-question", data);
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async updateDisqualifier({}, data) {
    let resp = await axios.post("/api/question/update-disqualifier", data);
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async updateTagId({}, data) {
    let resp = await axios.post("/api/question/update-tag-id", data);
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async updateSublabel({}, data) {
    let resp = await axios.post("/api/question/update-sublabel", data);
    return resp;
  },
  async getallTemplate({ commit }) {
    let resp = await axios.get("/api/get-template");
    if (resp.data.status == true) {
      commit("SET_TEMPLATE", resp.data.data);
    }
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async getQuizTemplate({}, data) {
    let resp = await axios.post("/api/question/quiz-template", data);
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async updateTemplate({}, data) {
    let resp = await axios.post("/api/question/save-theme", data);
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async saveTheme({}, data) {
    let resp = await axios.post("/api/question/save-theme", data);
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async getWelcomeData({}, id) {
    let resp = await axios.get("/api/question/get-welcome-screen/" + id);
    return resp;
  },

  //  eslint-disable-next-line no-empty-pattern
  async updateStatusWelcome({}, data) {
    let resp = await axios.post("/api/question/update-status-welcome", data);
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async updateWelcome({}, data) {
    let resp = await axios.post("/api/question/update-welcome", data);
    return resp;
  },
  async getRedirectScreen({ commit }, id) {
    let resp = await axios.get("/api/question/get-redirect-screen-data/" + id);
    if (resp.data.status == true) {
      commit("GET_REDIRECT_SCREEN_DATA", resp.data.data);
    }
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async updateRedirectScreenUrl({}, data) {
    let resp = await axios.post(
      "/api/question/update-redirect-screen-url",
      data
    );
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async updateRedirect({}, data) {
    let resp = await axios.post("/api/question/update-redirect-screen", data);
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  getEntries({}, data) {
    let resp = axios.post("/api/quiz-details/get-entries", data);
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  getEntryResponse({}, id) {
    let resp = axios.get("/api/quiz-details/entries/" + id);
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  deleteEntryResponse({}, id) {
    let resp = axios.get("/api/quiz-details/entries/delete/" + id);
    return resp;
  },
  async setTagData({ commit }) {
    let resp = await axios.get("/api/integration/get-tags");
    commit("SET_TAGS", resp.data.data);
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async updateSubmissions({}, data) {
    let resp = await axios.post("/api/question/update-automation", data);
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async getSubmissions({}, id) {
    let resp = await axios.get("/api/question/get-automation/" + id);
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async getStats({}, id) {
    let resp = await axios.get("/api/statistics/get-stats/" + id);
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async allQuestion({ commit }, id) {
    let resp = await axios.get("/api/statistics/all-question/" + id);
    if (resp.data.status == true) {
      commit("SET_ALL_QUESTION", resp.data.data);
    }
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async allQuestionInput({ commit }, data) {
    let resp = await axios.post(
      "/api/statistics/all-question-with-input",
      data
    );
    if (resp.data.status == true) {
      commit("SET_QUESTION_INPUT", resp.data.data);
    }
    return resp;
  },
  updateTemplateId({ state }, template_id) {
    if (state.singleQuiz) {
      state.singleQuiz.template = template_id;
      //Update template id on main quizzes array
      if (state.quiz && state.quiz.data && state.quiz.data.length > 0) {
        let index = state.quiz.data.findIndex(
          (q) => q.id == state.singleQuiz.id
        );
        if (state.quiz.data[index])
          state.quiz.data[index].template = template_id;
      }
    }
  },
  openSidebar({ commit }, data) {
    commit("increment_count");
    commit("SET_SERCH_QUESTION", data);
  },
  //  eslint-disable-next-line no-empty-pattern
  async getQuestionResponse({}, id) {
    let resp = await axios.get("/api/statistics/get-statistic-entires/" + id);
    return resp;
  },
  async getPowerTypes({ commit }, id) {
    commit("SET_POWERTYPE_QUESTION", []);
    let resp = await axios.get(
      "/api/jump-logic/get-powertypes-questions/" + id
    );
    if (resp.data.status == true) {
      commit("SET_POWERTYPE_QUESTION", resp.data.data);
    }
    return resp;
  },
  async getallQuestionAction({ commit }, id) {
    commit("SET_ALL_QUESTION_JUMP", []);
    let resp = await axios.get("/api/jump-logic/get-all-question/" + id);
    if (resp.data.status == true) {
      commit("SET_ALL_QUESTION_JUMP", resp.data.data);
    }
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async saveJumpLogic({}, data) {
    let resp = await axios.post("/api/jump-logic/save-logic", data);
    return resp;
  },
  async getJumpData({ commit }, id) {
    commit("SET_JUMP_DATA", []);
    let resp = await axios.get("/api/jump-logic/total-logic-data/" + id);
    if (resp.data.status == true) {
      commit("SET_JUMP_DATA", resp.data.data);
    }
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async deleteJumpLogic({}, id) {
    await axios.get("/api/jump-logic/delete/" + id);
  },
  async setSelectedSetting({ commit }, data) {
    commit("SET_SELECTED_SETTING", data);
  },
  //  eslint-disable-next-line no-empty-pattern
  async updateQuizSetting({}, data) {
    let resp = await axios.post("/api/quiz/quiz-setting/update", data);
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async updateQuizTemplateCss({}, data) {
    let resp = await axios.post("/api/quiz-template/css/update", data);
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  async updateTemplateImage({}, data) {
    let resp = await axios.post(
      "/api/quiz-template/template_image/update",
      data
    );
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateMessageDuration({}, data) {
    let resp = await axios.post("/api/question/update-message-duration", data);
    return resp;
  },
  //  eslint-disable-next-line no-empty-pattern
  deleteAutomation({}, id) {
    let resp = axios.get("/api/question/delete-automation/" + id);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateCompletePercentage({}, data) {
    let resp = await axios.post(
      "/api/question/update-complete-percentage",
      data
    );
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateJumpStatus({}, data) {
    let resp = await axios.post("/api/question/update-jump-status", data);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async getPowerQuestions({}, quizId) {
    let resp = await axios.get("/api/question/get-power-question/" + quizId);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateRedirectSetting({}, payload) {
    let resp = await axios.post(
      "/api/redirect/update-redirect-setting",
      payload
    );
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async saveExitRedirect({}, payload) {
    let resp = await axios.post("/api/redirect/exit-redirect/save", payload);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async saveQuizAutomation({}, payload) {
    let resp = await axios.post("/api/quiz-automation/save", payload);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async getAutomation({}, quizId) {
    let resp = await axios.get("/api/quiz-automation/get/" + quizId);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateRatingQuestion({}, data) {
    let resp = await axios.post("/api/question/update-rating-question", data);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateQuestionInput({}, data) {
    let resp = await axios.post("/api/question/update-question-input", data);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateQuestionInputImage({}, data) {
    let resp = await axios.post(
      "/api/question/update-question-input-image",
      data
    );
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateQuestionVideoUrl({}, data) {
    let resp = await axios.post(
      "/api/question/update-question-video-url",
      data
    );
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateQuestionImageData({}, data) {
    let resp = await axios.post(
      "/api/question/update-question-image-data",
      data
    );
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateLibraryImage({}, data) {
    let resp = await axios.post("/api/question/update-library-image", data);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateLibrarySetting({}, data) {
    let resp = await axios.post("/api/question/update-library-setting", data);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async setupUrl({state}, data) {
    let resp = await axios.post("/api/quiz/setup-url", data);
    if (resp.data.status == true) {
      var response = resp.data.data.link;
      console.log(response);
      var newQuizs = state.quiz.data.map((quiz) =>
        quiz.id === response.quiz_id
          ? { ...quiz, links: response }
          : quiz
      );
      state.quiz.data=newQuizs
    }
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async addQuizCategory({}, data) {
    let resp = await axios.post("/api/quiz/category/add", data);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async getQuizCategory({}, data) {
    let resp = await axios.post("/api/quiz/category/get", data);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async removeQuizCategory({}, id) {
    let resp = await axios.post(`/api/quiz/category/remove/${id}`);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async quizTagUpdate({state}, data) {
    let resp = await axios.post("/api/quiz/update-quiz-tag", data);
    if (resp.data.status == true) {
      var response = resp.data.data;
      console.log(response);
      var newQuizs = state.quiz.data.map((quiz) =>
        quiz.id === response.quiz_id
          ? { ...quiz, standard_id: response.standard_id, qualified_id: response.qualified_id, disqualified_id : response.disqualified_id }
          : quiz
      );
      state.quiz.data=newQuizs
    }
    return resp;
  },
  async setCurrentQuestion({ commit }, data) {
    commit("SET_CURRENT_QUESTION", data);
  },
  // eslint-disable-next-line no-empty-pattern
  async saveQuestionInput({}, data) {
    let resp = await axios.post("/api/question/score", data);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async changeScoreCategory({}, data) {
    let resp = await axios.post("/api/question/change-score-category", data);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async changeScoreCategoryId({}, data) {
    let resp = await axios.post("/api/question/save-category", data);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async saveQuizScore({}, data) {
    let resp = await axios.post("/api/quiz/score/save", data);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async getQuizScore({}, data) {
    let resp = await axios.post("/api/quiz/score/get", data);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async changeScoreStatus({}, data) {
    let resp = await axios.post("/api/quiz/redirect-status", data);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async changetaggingOption({}, data) {
    let resp = await axios.post("/api/quiz/tagging-option/set", data);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async removeQuizScore({}, id) {
    let resp = await axios.get(`/api/quiz/score/delete/${id}`);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async generateAiQuestion({}, data) {
    let resp = await axios.post('/api/quiz/ai', data);
    return resp;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateQuizName({}, data) {
    let resp = await axios.post(
      "/api/quiz/name/update",
      data
    );
    return resp;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
