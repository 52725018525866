import axios from '@/plugins/apiService.js' 
const state = {
    domains:[],
};


// mutations
const mutations = {
  SET_DOMAINS(state, value) {
    state.domains = value;
  },
};

// getters
const getters = {
  getDomainsData(state){
      return state.domains
  }
}
//actions
const actions = {
  /** User login  **/
  async fetchDomainList({ commit }) {
    let resp = await axios.get('/api/domain/get-list');
    if (resp.data.status == true) {
      commit("SET_DOMAINS", resp.data.data);
    }
    return resp;
  },
  async addDomain({ commit }, payload) {
    let resp = await axios.post('/api/domain/add-update',payload);
    if (resp.data.status == true) {
      commit("SET_DOMAINS", resp.data.data);
    }
    return resp;
  },
  async deleteDomain({ commit }, payload) {
    let resp = await axios.post('/api/domain/delete',payload);
    if (resp.data.status == true) {
      commit("SET_DOMAINS", resp.data.data);
    }
    return resp;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
