import { createStore } from "vuex";
import { config } from "vuex-module-decorators";
import Auth from "@/store/jsStore/modules/Auth.js";
import Quiz from "@/store/jsStore/modules/Quiz.js";
import Dashboard from "@/store/jsStore/modules/Dashboard.js";
import AuthModule from "@/store/modules/AuthModule.ts";
import BodyModule from "@/store/modules/BodyModule.ts";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule.ts";
import ConfigModule from "@/store/modules/ConfigModule.ts";
import GC from "@/store/jsStore/modules/GC.js";
import Pexels from "@/store/jsStore/modules/Pexels.js";
import SOB from "@/store/jsStore/modules/SOB.js";
import Domain from "@/store/jsStore/modules/Domain.js";

import VuexPersistence from 'vuex-persist'

config.rawError = true;

const vuexLocal = new VuexPersistence({
  modules: ['Auth'],
  })
const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    Auth,
    Quiz,
    Dashboard,
    GC,
    Pexels,
    SOB,
    Domain
  },
  plugins: [vuexLocal.plugin],
});

export default store;
