import { createWebHistory, createRouter } from "vue-router"
import store from "@/store"
import agnecyRoutes from "@/router/roles/agency.js"
// import userRoutes from '@/router/roles/user.js';
/* eslint-disable */
/* eslint-enable */
const routes = [
  // {
  //   path: "/",
  //   component:MainApp,
  //   children: [
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("@/views/dashboard/Dashboard.vue"),
      },
      {
        path: "/lead-page",
        name: "LeadPage",
        component: () => import("@/views/lead-page/Index.vue"),
      },
      {
        path: "/gallery",
        name: "Gallery",
        component: () => import("@/views/gallery/Index.vue"),
      },
      {
        path: "/editor",
        name: "Editor",
        component: () => import("@/views/editor/Index.vue"),
      },
      {
        path: "global-control",
        name: "GlobalControl",
        component: () => import("@/views/GlobalControl.vue"),
      },
      {
        path: "/dashboard-2",
        name: "Dash",
        component: () => import("@/views/Dashboard.vue"),
      },
      ...agnecyRoutes,
      {
        path: "/gc-screen",
        name: "gcSceeen",
        component: () => import("@/views/gc/gcsreen.vue"),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/video-tutorials",
        name: "VideoTutorials",
        component: () => import("@/views/VideoTutorials.vue"),
      },
      {
        path: "/onboarding",
        name: "Onboarding",
        component: () => import("@/views/Onboarding.vue"),
      },
      {
        path: "/splash",
        name: "Splash",
        component: () => import("@/views/Splash.vue"),
      },
      // ...userRoutes,
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () => import("@/views/auth/ForgotPassword.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: () => import("@/views/auth/Register.vue"),
  //   meta: {
  //     requiresAuth: false,
  //   },
  // },
  //   {
  //     path:'/gc-screen',
  //     name:'gcSceeen',
  //     component:() =>import("@/views/gc/gcsreen.vue"),
  //     meta: {
  //       requiresAuth: false
  //   }
  // },
  // ]
  // },
  {
    path: "/page/quiz/:uniq_code",
    name: "QuizView",
    component: () => import("@/views/quiz/TemplateView.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})
/* eslint-disable */
router.beforeEach(async (to, from, next) => {
  const isLoggedIn = store.state.Auth.authenticated
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const isAgency = to.matched.some((record) => record.meta.isAdmin)
  const isUser = to.matched.some((record) => record.meta.isUser)
  let user = store.state.Auth.user
  const { path, name, params } = to
  if (isLoggedIn && ["login", "forgotPassword", "forgotView", "Register"].includes(name)) {
    return next("/")
  } else if (requiresAuth && isLoggedIn && isAgency && user.type != "agency") {
    return next("/login")
  } else if (requiresAuth && isLoggedIn && isUser && user.type != "user") {
    return next("/login")
  } else if (requiresAuth && !isLoggedIn) {
    return next("/login")
  }
  return next()
})
/* eslint-enable */

export default router
